export default {
  state: {
    cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
  },
  mutations: {
    APPEND_CART_ITEM(state, item) {
      let findIndex = state.cart.findIndex(i => i.id === item.id);

      if (findIndex > -1) {
        state.cart[findIndex].count += item.count;
      } else {
        state.cart.push(item);
      }

      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    SET_CART_ITEM_COUNT(state, {id, count}) {
      let findIndex = state.cart.findIndex(i => i.id === id);

      if (findIndex > -1) {
        state.cart[findIndex].count = count;
      }

      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    REMOVE_CART_ITEM(state, id) {
      let findIndex = state.cart.findIndex(i => i.id === id);
      state.cart.splice(findIndex, 1);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    SET_CART_ITEMS(state, payload) {
      state.cart = payload;
      localStorage.setItem('cart', JSON.stringify(payload));
    }
  },
  actions: {
    ADD_CART_ITEM(context, payload) {
      context.commit('APPEND_CART_ITEM', {
        id: payload.item.id,
        name: payload.item.cart_name || payload.item.title,
        price: payload.item.price,
        count: payload.qt,
        type: payload.type
      })
    },
    REMOVE_CART_ITEM(context, payload) {
      context.commit('REMOVE_CART_ITEM', payload)
    },
    REMOVE_CART_ITEMS(context, payload) {
      context.commit("SET_CART_ITEMS", []);
    },
    SET_CART_COUNT(context, { id, qt }) {
      context.commit('SET_CART_ITEM_COUNT', {
        id: id,
        count: qt,
      })
    }
  },
  getters: {
    CART_ITEMS: state => {
      return state.cart;
    }
  }
}
