import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import DefaultLayout from './layouts/Default';
import AdminLayout from './layouts/Admin';
import NoLayout from './layouts/NoLayout';
import Loader from './components/Loader';
import './scss/main.scss';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Snotify, {SnotifyPosition} from 'vue-snotify'
import VueGtag from "vue-gtag";
import Meta from "vue-meta";
import VueGtm from 'vue-gtm';
import VueYandexMetrika from 'vue-yandex-metrika'
import VueI18n from 'vue-i18n'


Vue.mixin({
    data() {
        return {
            refreshTimeout: false
        }
    },
    methods: {
        chunk(array, size) {
            const chunked_arr = [];
            let copied = [...array]; // ES6 destructuring
            const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
            for (let i = 0; i < numOfChild; i++) {
                chunked_arr.push(copied.splice(0, size));
            }
            return chunked_arr;
        },
        getTextFromHTML(html) {
            let escape = document.createElement('div');
            escape.innerHTML = html;
            return escape.innerText;
        }
    },
    updated() {
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(function () {
            AOS.refresh();
        }, 300);
    },
});
Vue.component('default-layout', DefaultLayout);
Vue.component('admin-layout', AdminLayout);
Vue.component('no-layout', NoLayout);
Vue.component('loader', Loader);
Vue.use(require('vue-moment'));
Vue.use(BootstrapVue);
Vue.use(Meta);
Vue.use(VueYandexMetrika, {
    id: '68695942',
    router: router,
    env: process.env.NODE_ENV,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    }
})
Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: 'veeFields',
    errorBagName: 'veeErrors',
    dictionary: {
        en: {
            messages: {
                required: (field, args) => `Required`,
                email: (field, args) => `Must be a valid email address.`,
                min: (field, args) => {
                    if (field === "phone") {
                        return 'Phone number must have ten digits'
                    }
                    return `The ${field} must have at least ${args} characters`
                }
            }

        }
    }
});

Vue.use(VueGtm, {
    id: 'GTM-TFTJXB7',
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
});

const options = {
    toast: {
        position: SnotifyPosition.rightTop
    }
}

Vue.use(Snotify, options);
Vue.use(VueI18n)
const messages = {
    en: {
        message: {
            employment_form: 'Employment Form',
            yes: 'Yes',
            no: 'No',
            first_name: 'First Name',
            last_name: 'Last Name',
            email: 'Email',
            phone: 'Phone',
            city: 'City where you live',
            date_of_birth: 'Date Of birth',
            employment_type: 'What type of employment are you looking for?',
            full_time: 'Full time',
            part_time: 'Part time',
            seasonal: 'Seasonal',
            languages: 'Do you speak any other languages?',
            education_level: 'What is the highest level of education you have completed?',
            middle_school: 'Middle school',
            high_school: 'High school diploma',
            ged: 'GED',
            some_college: 'Some college',
            two_degree: '2 year degree',
            four_degree: '4 year degree',
            last_job: 'What is your current job or what was the last job that you had?',
            work_experience: 'What is your other prior work experience? Please list dates',
            hourly_rate: 'Hourly Rate desired',
            tell_yourself: 'Tell us about yourself',
            about_us: 'How did you hear about us?',
            hard_work_one: 'This job requires the ability to lift 100 lbs on a nearly constant basis throughout the day.',
            hard_work_two: 'Workdays can be over 12 hours. Are you physically able to do this work?',
            experience_customers: 'Do you have experience working directly with customers?',
            references: 'References',
            references_list: 'Please list three references in the space below with phone numbers:',
            reference_one: 'Reference 1: Name, Phone Number, Title, Relationship to applicant',
            reference_two: 'Reference 2: Name, Phone Number, Title, Relationship to applicant',
            reference_three: 'Reference 3: Name, Phone Number, Title, Relationship to applicant',
            convicted_felony: 'Have you been convicted of a felony in the past 7 years?',
            felony_description: 'If yes please describe',
            driver_license: 'Do you have a valid driver\'s license at this time?',
            medical_conditions: 'Do you have any medical conditions? If Yes please explain.',
            suspended_driver_license: 'Have you had your driver\'s license suspended?',
            nationwide_check: 'Do you consent to a nationwide background check?',
            drug_test: 'Do you consent to a drug test prior to or during employment?',
            us_citizen: 'Are you a US citizen or do you have a proper visa that allows you to work in the United States',
            consent: 'Consent',
            consent_description: 'I certify that all information I have provided is accurate to the best of my knowledge. I understand and accept that any false statements made on this application are grounds for immediate termination if hired.',
            submit: 'SUBMIT',
        }
    },
    es: {
        message: {
            employment_form: 'Formulario de empleo',
            yes: 'Sí',
            no: 'No',
            first_name: 'Nombre',
            last_name: 'Apellido',
            email: 'Email',
            phone: 'Teléfono',
            city: 'Ciudad de vivir',
            date_of_birth: 'Fecha de nacimiento',
            employment_type: 'Que tipo de trabajo quiere?',
            full_time: 'Tiempo completo',
            part_time: 'Tiempo parcial',
            seasonal: 'Estacional',
            languages: 'Hablás algún otro idioma?',
            education_level: 'Cuál es el nivel más alto de educación que ha completado?',
            middle_school: 'Escuela intermedia',
            high_school: 'Diploma de escuela secundaria',
            ged: 'GED',
            some_college: 'Alguno college',
            two_degree: 'Grado de 2 años',
            four_degree: 'Grado de 4 años',
            last_job: 'Cuál es su trabajo actual o cuál fue el último trabajo que tuvo?',
            work_experience: 'Cuál es su otra experiencia laboral anterior? Por favor enumere las fechas',
            hourly_rate: 'Tarifa horaria',
            tell_yourself: 'Cuéntanos acerca de tú mismo',
            about_us: 'Cómo te enteraste de nosotros?',
            hard_work_one: 'Este trabajo requiere la capacidad de levantar 100 libras de forma casi constante durante todo el día.',
            hard_work_two: 'La jornada laboral puede ser superior a 12 horas.Está usted físicamente capacitado para hacer este trabajo?',
            experience_customers: 'Tiene experiencia trabajando directamente con los clientes?',
            references: 'Referencias',
            references_list: 'Enumere tres referencias en el espacio a continuación con números de teléfono:',
            reference_one: 'Referencia 1: nombre, número de teléfono, título, relación con el solicitante',
            reference_two: 'Referencia 2: nombre, número de teléfono, título, relación con el solicitante',
            reference_three: 'Referencia 3: Nombre, número de teléfono, título, relación con el solicitante',
            convicted_felony: 'Ha sido condenado por un delito grave en los últimos 7 años?',
            felony_description: 'En caso afirmativo, describa',
            driver_license: 'Tiene una licencia de conducir válida en este momento?',
            medical_conditions: 'Tienes alguna condición médica? En caso afirmativo, explíquelo porfavor.',
            suspended_driver_license: 'Le han suspendido la licencia de conducir?',
            nationwide_check: 'Acepta una verificación de antecedentes a nivel nacional?',
            drug_test: 'Consiente en una prueba de drogas antes o durante el empleo?',
            us_citizen: 'Es ciudadano estadounidense o tiene una visa adecuada que le permita trabajar en los Estados Unidos?',
            consent: 'Consentir',
            consent_description: 'Certifico que toda la información que he proporcionado es exacta a mi leal saber y entender. Entiendo y acepto que cualquier declaración falsa hecha en esta solicitud es motivo de despido inmediato si soy contratado.',
            submit: 'SUBMIT',
        }
    }
}

const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
})

Vue.config.productionTip = process.env.VUE_APP_PROD;

Vue.use(VueGtag, {
    config: { id: "G-64HQP6SZGD" }
}, router);

new Vue({
    created() {
        AOS.init();
    },
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
