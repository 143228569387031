import { RepositoryFactory } from "../../api/RepositoryFactory";

export default {
    state: {
        site_details: {},
        home_page: {},
        specializes: [],
        home_specializes: [],
        moving_sizes: [],
    },
    mutations: {
        SET_SITE_DETAILS: (state, payload) => {
            state.site_details = payload;
        },
        SET_HOME_DETAILS: (state, payload) => {
            state.home_page = payload;
        },
        SET_SPECIALIZE_DETAILS: (state, payload) => {
            state.specializes = payload;
        },
        SET_HOME_SPECIALIZE_DETAILS: (state, payload) => {
            state.home_specializes = payload;
        },
        SET_MOVING_SIZES: (state, payload) => {
            state.moving_sizes = payload;
        },
    },
    actions: {
        GET_SITE_DETAILS: async (context, payload) => {
            RepositoryFactory.get('site').get().then(({ data: { settings } }) => {
                context.commit('SET_SITE_DETAILS', settings);
            })
        },
        GET_HOME_PAGE_INFO: async (context, payload) => {
            RepositoryFactory.get('site').getHome().then(({ data: { home } }) => {
                context.commit('SET_HOME_DETAILS', home);
            })
        },
        GET_SPECIALIZE_DETAILS: async (context, payload) => {
            RepositoryFactory.get('site').getSpecialize().then(({ data: { specializes } }) => {
                context.commit('SET_SPECIALIZE_DETAILS', specializes);
            })
        },
        GET_HOME_SPECIALIZE_DETAILS: async (context, payload) => {
            RepositoryFactory.get('site').getHomeSpecialize().then(({ data: { specializes } }) => {
                context.commit('SET_HOME_SPECIALIZE_DETAILS', specializes);
            })
        },
        GET_MOVING_SIZES: async (context, payload) => {
            RepositoryFactory.get('site').getMovingSizes().then(({ data: { sizes } }) => {
                context.commit('SET_MOVING_SIZES', sizes);
            })
        },
        GET_STRIPE_SESSION_ID({ context }, cart) {
            return RepositoryFactory.get('site').getStripeKey(cart);
        },
        CHANGE_ORDER_STATUS({ context }, payload) {
            return RepositoryFactory.get('site').changeOrderStatus(payload);
        }
    },
    getters: {
        SITE_DETAILS: state => {
            return state.site_details;
        },
        HOME_PAGE: state => {
            return state.home_page;
        },
        SPECIALIZES: state => {
            return state.specializes;
        },
        HOME_SPECIALIZES: state => {
            return state.home_specializes;
        },
        MOVING_SIZES: state => {
            return state.moving_sizes;
        },
    }
}
