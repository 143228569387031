import Repository from './Repository';

const resource = '/info';

export default {
    get()      {return Repository.get(`${resource}`);},
    getTestimonials() {return Repository.get(`${resource}/testimonials`);},
    getSliders() {return Repository.get(`${resource}/slider/images`);},
    joinUsSubmit(payload) {return Repository.post(`/join/us`, payload);},
    firstSubmit(payload) {return Repository.post(`/get/quote/first`, payload);},
    submitForm(payload) {return Repository.post(`/get/quote`, payload);},
    contact(payload) {return Repository.post(`/contact`, payload);},
    getVideos() {return Repository.get(`${resource}/videos`);},
    getAbout() {return Repository.get(`${resource}/about`);},
    getHome() {return Repository.get(`${resource}/home/page`);},
    getSpecialize() {return Repository.get(`${resource}/specializes`);},
    getHomeSpecialize() {return Repository.get(`${resource}/home_specializes`);},
    getMovingSizes() {return Repository.get(`${resource}/sizes`);},
    getStripeKey(cart) {return Repository.post(`get/session`, cart);},
    changeOrderStatus(payload) {return Repository.post(`change-order-status`, payload);},
    employmentForm(payload) {return Repository.post(`/employment-form`, payload);},
}
