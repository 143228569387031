import Repository from './Repository';

const resource = '/locations';

export default {
    getNames()      {return Repository.get(`${resource}/names`);},
    getAreaServedNames()      {return Repository.get(`area_served/names`);},
    getPickupLocations()      {return Repository.get(`pickup_locations`);},
    show(slug)      {return Repository.get(`${resource}/${slug}/show`);},
    showLocations(slug)      {return Repository.get(`${resource}/${slug}/show/locations`);},
    get(params)     {return Repository.get(`${resource}`, params);},
}
