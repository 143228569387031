import { RepositoryFactory } from "../../api/RepositoryFactory";

export default {
    state: {
        locations: {},
        area_served: [],
        pickup_locations: [],
    },
    mutations: {
        SET_LOCATIONS: (state, payload) => {
            state.locations = payload;
        },
        SET_AREA_SERVED: (state, payload) => {
            state.area_served = payload;
        },
        SET_PICKUP_LOCATIONS: (state, payload) => {
            state.pickup_locations = payload;
        },
    },
    actions: {
        GET_LOCATION_NAMES: async (context, payload) => {
            RepositoryFactory.get('location').getNames().then(({ data: { locations } }) => {
                context.commit('SET_LOCATIONS', locations);
            })
        },
        GET_AREA_SERVED_NAMES: async (context, payload) => {
            RepositoryFactory.get('location').getAreaServedNames().then(({ data: { area_served } }) => {
                context.commit('SET_AREA_SERVED', area_served);
            })
        },
        GET_PICKUP_LOCATIONS: async (context) => {
            RepositoryFactory.get('location').getPickupLocations().then(({ data: { pickup_locations } }) => {
                context.commit('SET_PICKUP_LOCATIONS', pickup_locations);
            })
        }
    },
    getters: {
        LOCATIONS: state => {
            return state.locations;
        },
        AREA_SERVED: state => {
            return state.area_served;
        },
        PICKUP_LOCATIONS: state => {
            return state.pickup_locations;
        },
    }
}
